<template>
  <div
    v-if="showItemPicker"
    id="vue-item-picker"
    :class="classes"
    :style="style"
  >
    <template v-if="displayColumns.length > 0">
      <div
        class="item-picker-columns"
        :style="rowStyle"
      >
        <div
          v-if="multiSelect && !loadingOptions && !itemPickerProps.hideToggleAll"
          class="bulk-add-btn"
          @click="toggleAllPickerItems"
        >
          <span :class="toggleAllBtnClasses" />
        </div>
        <div
          v-for="(displayColumn, index) in displayColumns"
          :key="index"
          class="item-picker-column"
        >
          <v-text-field
            v-model="pickerFilterValues[displayColumn]"
            class="regular-vinput"
            :label="label(displayColumn)"
            hide-details
            @click:append="getItemPickerOptions"
            @keyup.enter="getItemPickerOptions"
          >
            <div
              slot="append"
              class="icons"
            >
              <v-icon @click.stop="getItemPickerOptions">
                fa-search
              </v-icon>
            </div>
          </v-text-field>
        </div>
      </div>
    </template>
    <div
      v-if="loadingOptions"
      class="options-loader"
    >
      <v-progress-linear
        :indeterminate="true"
        height="2"
        color="grey"
      />
    </div>
    <div
      v-else
      id="picker-options"
      ref="picker-options"
      :class="(displayColumns.length ? 'options-with-columns-search' : '') + ' picker-options'"
      :style="rowStyle"
      @scroll.passive="scrollHandler"
    >
      <div
        v-for="(pickerItem, index) in pickerItems"
        :key="index"
        :class="(index === selectedOptionIndex ? 'preselected' : '') +
          ' picker-item popup-menu-el'"
        :style="pickerItem['@row_style']"
        @click="selectPickerOption(pickerItem)"
      >
        <span
          v-if="multiSelect"
          :class="iconClasses(pickerItem)"
          style="position: absolute !important;"
        />
        <div
          v-for="(pickerColumn, columnIndex) in pickerOptionColumns"
          :key="columnIndex"
          v-tooltip="tooltips[columnIndex + '_' + index]"
          @mouseover="showTooltip($event, columnIndex, index)"
        >
          <ContentBoolean
            v-if="pickerColumn.field && pickerColumn.field.attribute_type === 'boolean'"
            :list-item="pickerItem"
            :field="pickerColumn.field"
            :cell-value="{ value: pickerItem[pickerColumn.name] }"
          />
          <ContentReference
            v-else-if="pickerColumn.field && ['reference', 'process', 'address', 'polymorphic_autocomplete'].includes(pickerColumn.field.attribute_type)"
            :list-item="pickerItem"
            :disable-link="true"
            :field="{name: pickerColumn.name, ...pickerColumn.field }"
          />
          <ContentHasManyReferenceItems
            v-else-if="pickerColumn.field && pickerColumn.field.attribute_type === 'has_many_reference'"
            :field="{name: pickerColumn.name, ...pickerColumn.field }"
            :item="pickerItem"
            :on-single-line="true"
          />
          <ObjectStateLabel
            v-else-if="pickerColumn.field && pickerColumn.field.attribute_type === 'state'"
            :item="pickerItem"
          />
          <div
            v-else
            class="empty-cell-take-space"
          >
            <!-- TODO - is there a case where it should be v-html? Used to be but then Oma>messages>sender does not show values <e-mail.address@something.com> -->
            {{ displayValue(pickerColumn, pickerItem) }}
          </div>
        </div>
      </div>
      <div
        v-if="loadingMoreOptions"
        class="more-options-loader"
      >
        <v-progress-linear
          :indeterminate="true"
          height="1"
          color="secondary"
        />
      </div>
      <div
        v-else-if="pickerItems.length === 0"
        class="pt-2 pl-5 grey--text"
      >
        {{ $i18n.t('aava.general.no_objects') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import util from '../../utilities/sharedUtilities'
import listViewAPI from './../../store/api'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import dateTimeHelper from '@/methods/date_time_helper'
import ContentBoolean from '@/components/ListContent/DataTypes/ContentBoolean.vue'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import ContentReference from '@/components/ListContent/DataTypes/ContentReference.vue'
import translateAttribute from '@/utilities/translateAttribute'
import ContentHasManyReferenceItems from '../ListContent/DataTypes/ContentHasManyReferenceItems.vue'
import ObjectStateLabel from '../Form/ObjectStateLabel.vue'
import { BaseItem, Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const advancePreloadPixelsLimit = 100 // Can be small as many batches are loaded
const columnWidth = 200

export default {
  name: 'ItemPicker',

  components: {
    ObjectStateLabel,
    ContentHasManyReferenceItems,
    ContentReference,
    ContentBoolean,
  },

  data () {
    return {
      pickerFilterValues: {},
      pickerItems: [] as Types.Item[],
      pickerTotalCount: 0,
      loadingOptions: false,
      loadingMoreOptions: false,
      timer: null,
      selectedOptionIndex: -1,
      forField: null,
      selectColumnsAm: null, // To show column content based on it's data type
      addedIds: [],
      tooltips: {},
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    // Picking new items from has-many list through reference field values
    selectingNewHasManyThroughReferenceValues () {
      // Use disableDeselect as it is disabled only for this case
      return this.itemPickerProps?.disableDeselect
    },

    searchTimerDelay () {
      return this.isSearchStringType ? 0 : 700
    },

    pickerReferenceAttribute () {
      return this.itemPickerProps?.forField?.reference_attribute || 'summary'
    },

    isSearchStringType () {
      return this.itemPickerProps?.forField?.type === 'search_string'
    },

    ignoreSearchTerm () {
      return this.itemPickerSelectedOption[this.pickerReferenceAttribute] === this.itemPickerSearchTerm
    },

    allOptionsLoaded () {
      return this.pickerTotalCount === this.pickerItems.length
    },

    pickerOptionColumns () {
      return this.selectColumns.length === 0
        ? [{ name: this.pickerReferenceAttribute }]
        : this.selectColumns.map(columnName => {
          return {
            name: columnName,
            field: this.selectColumnsAm?.[columnName],
          }
        })
    },

    columnsCount () {
      return this.selectColumns.length > 0 ? this.selectColumns.length : 1
    },

    classes (): string[] {
      return [
        'item-picker-container',
        'popup-menu',
        'elevation-5',
        (this.pickerOptionColumns.length > 1 ? ' multi-column ' : ''),
        // Make item picker invisible when no items found
        // For string_search type do not need to show "nothing found" message,
        // user can enter free text
        this.isSearchStringType && this.loadingOptions && !this.pickerItems.length ? 'd-none' : '',
      ]
    },

    style () {
      const offset = 30
      const padding = 60
      return {
        width: (this.columnsCount * columnWidth + offset + padding) + 'px'
      }
    },

    multiSelect () {
      return !!this.itemPickerProps?.multiSelect
    },

    selectColumns () {
      return this.itemPickerProps?.selectColumns ||
        [this.pickerReferenceAttribute]
    },

    // If only one summary field, do not show search form for that
    displayColumns (): string[] {
      return this.selectColumns.length === 1 && this.selectColumns[0] === this.pickerReferenceAttribute
        ? this.itemPickerProps?.forHasMany ? [this.pickerReferenceAttribute] : []
        : this.selectColumns
    },

    rowStyle () {
      return {
        minWidth: (this.selectColumns.length * 200 + 60) + 'px' // column widths + padding 60
      }
    },

    selectedIds () {
      // In simple-multiselect component if picking items through multiselect_parent_attribute
      if (this.hasManyComponentForItemPicker?.selectedPickerItemIds) {
        return this.hasManyComponentForItemPicker?.selectedPickerItemIds
      }
      return (this.hasManyComponentForItemPicker?.items || []).map(option => option.id)
    },

    addQueueIds () {
      return (this.hasManyComponentForItemPicker?.addQueue || []).map(option => option.id)
    },

    toggleAllBtnClasses () {
      const classes: string[] = []
      // Hide toggle all for simple multiselect
      if (this.hasManyComponentForItemPicker?.selectedPickerItemIds) {
        classes.push('d-none')
      }
      if (this.addQueueIds.length > 0) {
        classes.push('disabled-cursor')
        classes.push('fa fa-spinner fa-spin')
      } else {
        classes.push(this.availableItemsToAdd.length === 0 ? 'fa-regular fa-square-check' : 'fa-regular fa-square')
      }
      return classes
    },

    availableItemsToAdd () {
      return this.pickerItems.filter(item => !this.selectedIds.includes(item.id))
    },
  },

  watch: {
    itemPickerSearchTerm () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (!this.ignoreSearchTerm) {
          this.selectedOptionIndex = -1
          this.getItemPickerOptions()
        }
      }, this.searchTimerDelay)
    },
  },

  created () {
    // Set component forField, used when closing picker popup to check that
    // we are not closing a picker for another field
    this.forField = this.itemPickerProps?.forFieldName
    this.selectedOptionIndex = -1
    // Set loader to true
    this.loadingOptions = true
    // But do not search immediately, give time for user input
    this.timer = setTimeout(() => {
      this.getItemPickerOptions()
    }, this.searchTimerDelay)
    document.addEventListener('click', this.clickListener)
    document.addEventListener('keyup', this.keyUpListener)
    document.addEventListener('keydown', this.keyDownListener)
  },

  destroyed () {
    this.$store.state.itemPickerSearchTerm = ''
    this.$store.dispatch('resetItemPickerData', this.forField)
    document.removeEventListener('click', this.clickListener)
    document.removeEventListener('keyup', this.keyUpListener)
    document.removeEventListener('keydown', this.keyDownListener)
  },

  methods: {
    ...methods,
    ...listItemCellMethods,

    // If text does not fit (isn't visible for the user fully), show tooltip on mouseover
    showTooltip (e: MouseEvent, colIndex, rowIndex) {
      const target = e.target as HTMLElement
      if (target.scrollWidth && target.scrollWidth > target.clientWidth) {
        this.$set(this.tooltips, colIndex + '_' + rowIndex, target.innerHTML)
      }
    },

    toggleAllPickerItems () {
      if (this.addQueueIds.length > 0) { return }
      if (this.availableItemsToAdd.length > 0) {
        this.availableItemsToAdd.forEach(item => {
          this.hasManyComponentForItemPicker.addQueue.push(item)
        })
        // Start processing from the queue
        if (this.hasManyComponentForItemPicker) {
          this.hasManyComponentForItemPicker.addNewSelectItemToStore()
        }
      } else if (this.selectedIds.length > 0) {
        this.pickerItems.filter(item => this.selectedIds.includes(item.id))
          .forEach(item => {
            this.itemPickerUnselectCallback(item.id)
          })
      }
    },

    iconClasses (pickerItem: BaseItem) {
      const classes: string[] = []
      const isAlreadySelected = this.selectedIds.includes(pickerItem.id) &&
        !this.selectingNewHasManyThroughReferenceValues // Not supported in this case
      if (this.addQueueIds.length > 0 && isAlreadySelected) {
        classes.push('selected-check-box-locked') // Can't remove until all add-queue items have been processed
      } else {
        classes.push(isAlreadySelected ? 'selected-check-box' : 'unselected-check-box')
      }
      if (this.addQueueIds.includes(pickerItem.id)) {
        classes.push('fa fa-spinner fa-spin')
      } else if (this.addedIds.includes(pickerItem.id) ||
          (this.selectedIds.includes(pickerItem.id) && this.itemPickerProps.disableDeselect)) {
        classes.push('fa fa-check')
      } else {
        classes.push(isAlreadySelected ? 'fa-regular fa-square-check' : 'fa-regular fa-square')
      }
      return classes
    },

    displayValue (pickerColumn, item) {
      const field = pickerColumn.field
      if (!field) { return item[pickerColumn.name || this.pickerReferenceAttribute] }
      field.type = field.attribute_type
      field.name = field.attribute_name
      const value = this.getItemFieldDisplayText({
        inputField: field,
        inputItem: item,
      })
      return value
    },

    label (selectColumn) {
      let modelName = this.itemPickerProps?.objectClass
      // If model = Any, take first Association Class to translate column titles
      if (modelName === 'any') {
        // When used on the main form, can use layoutProfileItemsByName
        // When used in has many, can use this.itemPickerProps.forField.association_types
        modelName = this.$store.getters.layoutProfileItemsByName[this.itemPickerProps?.forFieldName]?.association_classes?.[0] || this.itemPickerProps.forField.association_types?.[0] || modelName
        modelName = util.objectClassUnderscoredName(modelName)
      }
      return translateAttribute(util.objectClassUnderscoredName(modelName), selectColumn, this.locale, this.$i18n)
    },

    togglePickerItemSelection (item) {
      if (this.selectedIds.includes(item.id)) {
        // Can't unselect until all items from add-queue have been processed
        if (this.addQueueIds.length > 0) { return }
        this.itemPickerUnselectCallback(item.id)
      } else {
        this.itemPickerSelectCallback(item)
      }
    },

    selectPickerOption (option: Types.Item) {
      if (this.addQueueIds.includes(option.id)) { return }
      if (this.addedIds.includes(option.id) && this.itemPickerProps.disableDeselect) {
        return
      }
      this.addedIds.push(option.id) // Used only when adding through reference field options
      if (this.multiSelect) {
        this.togglePickerItemSelection(option)
      } else {
        // Used for bulk change
        this.itemPickerSelectedOption = option
        this.itemPickerSelectCallback(option)
        this.showItemPicker = false
      }
    },

    // Handle scroll event to load more has_many list options
    scrollHandler () {
      if (!this.$refs['picker-options'] || this.loadingOptions || this.loadingMoreOptions) {
        return
      }
      const listEl = this.$refs['picker-options']
      const leftToScroll = listEl.scrollHeight - listEl.scrollTop - listEl.clientHeight
      if (leftToScroll < advancePreloadPixelsLimit) {
        this.loadMoreOptions()
      }
    },

    focusNextElement () {
      // Logic form old portal, but works
      const allInputs = document.querySelectorAll('input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])') as NodeListOf<HTMLElement>
      const focusable = Array.from(allInputs).filter(input => {
        return input.offsetWidth > 0 || input.offsetHeight > 0 || input === document.activeElement
      })
      const index = focusable.indexOf(document.activeElement as HTMLElement)
      if (index > -1) {
        const nextElement = focusable[index + 1] || focusable[0]
        nextElement.focus()
      }
    },

    getItemPickerOptions (): void {
      if (!this.itemPickerProps) {
        return
      }
      if (this.isSearchStringType) {
        return this.getStringOptions()
      }
      this.loadingOptions = true
      this.loadingMoreOptions = false
      this.getOptions({ offset: 0 }).then(result => {
        const { apiResponse, selectColumnsAm } = result
        this.selectColumnsAm = selectColumnsAm
        this.loadingOptions = false
        this.setPickerOptions(apiResponse)
        // When only one found, set as selected
        if (!apiResponse || apiResponse?.data?.items?.length === 1) {
          this.selectedOptionIndex = 0
          // Additionally, if picker_auto_open_off AND Tab key was pressed
          // select this only option and close item picker
          if (this.itemPickerAutoSelectFirstWhenOptionsLoaded) {
            this.selectPickerOption(this.pickerItems[0])
            this.itemPickerAutoSelectFirstWhenOptionsLoaded = false
            this.focusNextElement()
          }
        }
        this.$nextTick(() => {
          const listEl = this.$refs['picker-options']
          if (listEl) {
            listEl.scrollTop = 0
          }
        })
      })
    },

    getStringOptions () {
      this.loadingOptions = true
      const filters = {
        value: this.itemPickerSearchTerm,
      }
      let path = ''
      const forItem = this.itemPickerProps?.forItem
      if (forItem?.targetObject) {
        // Path is needed for the api when requesting options for string_search in has-many
        path = '~path=' + forItem.targetObject['@class'] +
          ':' + (forItem.targetObject.token || forItem.targetObject.id) + '%20' + (this.itemPickerProps.forObjectClass) + '&'
      }
      listViewAPI.sendRequest('/api/' + this.itemPickerProps.objectClass + '/search_options/' + this.itemPickerProps.forId + '/' + this.itemPickerProps.forField.name, filters, [], path)
        .then((response: AxiosResponse) => {
          const responseData = response.data as {
            values?: {
              value: string;
              label: string;
            }[]
          }
          const items = [] as Record<string, string>[]
          if (Array.isArray(responseData?.values)) {
            responseData.values.forEach(option => {
              items.push({
                summary: option.value,
                summarySuffix: option.label,
              })
            })
          }

          this.loadingOptions = false
          this.setPickerOptions({ data: { items } })
          if (!items.length) {
            this.$store.dispatch('closeItemPicker')
          }

          this.$nextTick(() => {
            const listEl = this.$refs['picker-options']
            if (listEl) {
              listEl.scrollTop = 0
            }
          })
        })
    },

    setPickerOptions (apiResponse) {
      if (!apiResponse.data?.items) { return }
      this.pickerItems = apiResponse.data.items
      this.pickerTotalCount = apiResponse.data.total
    },

    loadMoreOptions () {
      if (!this.itemPickerProps) {
        return
      }
      if (this.isSearchStringType) { return }
      if (this.loadingMoreOptions || this.allOptionsLoaded) { return }
      this.loadingMoreOptions = true
      this.getOptions({ offset: this.pickerItems.length }).then(result => {
        const { apiResponse, selectColumnsAm } = result
        this.loadingMoreOptions = false
        apiResponse.data.items = [...this.pickerItems, ...apiResponse.data.items]
        this.setPickerOptions(apiResponse)
      })
    },

    getOptions ({ offset }) {
      return this.$store.dispatch('loadItemPickerOptions', {
        className: this.itemPickerProps.objectClass,
        fieldName: this.itemPickerProps.forFieldName,
        forField: this.itemPickerProps.forField,
        forClass: this.itemPickerProps.forObjectClass,
        pathField: this.itemPickerProps.pathField,
        referenceFieldName: this.pickerReferenceAttribute,
        referenceFieldSearchTerm: this.ignoreSearchTerm
          ? ''
          : this.itemPickerSearchTerm,
        selectColumns: this.selectColumns,
        filterValues: this.pickerFilterValues,
        offset,
        forItem: this.itemPickerProps.forItem,
      })
    },

    clickListener (e) {
      if (this.showItemPicker) {
        const el = document.getElementById('vue-item-picker')
        // Do not close popup if click is outside picker popup but is still
        // inside opener input
        const clickIsInsideOpenerInput = this.itemPickerProps.forFieldName &&
          e.target.dataset &&
          this.itemPickerProps.forFieldName === e.target.dataset.fieldName
        // Use picker opened timestamp to check if picker was just now opened
        // With events from various triggers not a good way to prevent popup immediately closing
        const pickerIsJustNowOpened = (dateTimeHelper.getTimestamp() - this.showItemPickerTimestamp) < 0.3
        if (!pickerIsJustNowOpened && el && !el.contains(e.target) && !clickIsInsideOpenerInput) {
          this.showItemPicker = false
        }
      }
    },

    keyDownListener (e: KeyboardEvent) {
      const optionHeight = 28
      if ((this.pickerItems?.length === 0 || this.selectedOptionIndex === -1) && ['Tab'].includes(e.key)) {
        this.showItemPicker = false
      } else if (!this.pickerItems) {
        // Stop here
      } else if (e.key === 'ArrowDown') {
        this.selectedOptionIndex++
        if (this.selectedOptionIndex >= this.pickerItems.length) {
          this.selectedOptionIndex = this.pickerItems.length - 1
        }
        this.scrollToPreselected()
      } else if (e.key === 'ArrowUp') {
        this.selectedOptionIndex--
        if (this.selectedOptionIndex < 0) {
          this.selectedOptionIndex = 0
        }
        this.scrollToPreselected()
      } else if (['Enter', 'Tab'].includes(e.key) && this.pickerItems[this.selectedOptionIndex]) {
        this.selectPickerOption(this.pickerItems[this.selectedOptionIndex])
      }
    },

    keyUpListener (e: KeyboardEvent) {
      if (['Escape', 'Esc'].includes(e.key)) {
        this.$store.dispatch('closeItemPicker')
        e.stopPropagation()
      }
    },

    scrollToPreselected () {
      if (!document.getElementById('picker-options')) { return }
      if (!document.getElementsByClassName('picker-item')?.[0]) {
        return
      }
      try {
        this.$vuetify.goTo('.picker-item:nth-child(' + (this.selectedOptionIndex + 1) + ')', {
          duration: 100,
          easing: 'easeInOutCubic',
          offset: 50,
          container: '#picker-options',
        })
      } catch (e) {
        console.warn('Items still loading')
      }
    },
  }
}
</script>

<style lang="scss">
.item-picker-container.multi-column {
  .item-picker-columns {
    /* padding: 3px 0 3px 15px; */
    .v-input .v-label {
      font-size: 14px;
    }
    .fa-search {
      font-size: 12px !important;
      padding-top: 10px;
    }
  }
  .popup-menu-el {
    /* padding: 3px 0 3px 15px !important; */
    >div {
      width: 200px;
      padding-right: 10px;
    }
  }

}
.item-picker-container {
	min-width: 300px;
	min-height: 261px; /* Enough height not to cover input in the bottom by ItemPicker with multiple columns */
  max-width: 95%;
  z-index: 301; /* Important to show above other popups */
  border-radius: 6px;
  overflow-x: auto;
  padding: 0;
  .v-chip.v-size--default {
    height: 20px;
    font-size: 12px;
  }
  .bulk-add-btn {
    padding: 5px;
    color: #9E9E9E;
    position: absolute;
    left: 9px;
    top: 24px;
    cursor: pointer;
  }
  .selected-check-box-locked {
    color: #757575;
    cursor: not-allowed;
  }
  .selected-check-box {
    color: #1B5E20;
  }
  .unselected-check-box {
    color: #9E9E9E;
  }
	.item-picker-columns {
    overflow: auto;
    padding: 3px 25px 3px 35px;
		.item-picker-column {
			float: left;
			width: 200px;
			padding: 0 2px 7px 2px;
		}
	}
	.picker-options {
		height: 261px;
		float: left;
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
    padding: 10px 0;
    .more-options-loader {
      padding: 5px;
      overflow: auto;
    }
	}
  /* If item-picker includes search inputs for columns, there is less space to list the options */
  .picker-options.options-with-columns-search {
    height: 200px;
  }
	.options-loader {
		padding: 20px;
		clear: both;
	}
	.picker-item {
		clear: both;
		/* width: 1000px; */
		float: left;
		cursor: pointer;
    .reference-icon {
      padding-top: 5px;
    }
		>div {
      float: left;
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
  .preselected {
    background: #FFF59D;
  }
	.icons {
		position: relative;
		width: 27px;
		.fa-search {
			font-size: 14px !important;
			position: absolute;
			top: -3px;
			padding: 4px 4px 4px 2px;
			right: 3px;
		}
	}
}
</style>
