import util from '@/utilities/sharedUtilities'
import api from '@/store/api/'
import { ItemMeta, LP, LPI } from '@/types/LP.types'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { EventAction } from '@/types/EventAction'
import { SaveFormItemProps } from '@/types/Item'
import { AxiosResponse } from 'axios'
import { BaseItem, Types } from '@/types/AppTypes'

export default {
  ...itemSaveQueueMethods,

  // Check if any DFC are in progress or coming
  // Do not immediately show error for the user, try to wait for DFC requests to finish first
  saveFormItemWhenDFCFinishes ({
    goBackToList,
    executeEventName,
    event,
    callbackBeforeShowViewOpen,
    tryCount = 0,
  }: {
    goBackToList: boolean,
    executeEventName: string,
    event: EventAction,
    callbackBeforeShowViewOpen: (payload: any) => any,
    tryCount?: number,
  }) {
    return new Promise(resolve => {
      // console.log('Try to save with count ', tryCount)
      // DFC is ongoing or in queue
      if (this.itemHasUnfinishedDfcRequests() || this.dfcRequestsComingFromTimer > 0) {
        // Over 3 seconds has passed, show error for the user that DFC is in progress and has to wait
        if (tryCount > 30) {
          this.showUnfinishedDfcRequestsError()
          this.showLoaderComputed = false
          return resolve(false)
        }
        tryCount++
        // Try again in 100 ms
        setTimeout(() => {
          resolve(this.saveFormItemWhenDFCFinishes({
            goBackToList,
            executeEventName,
            event,
            callbackBeforeShowViewOpen,
            tryCount,
          }))
        }, 100)
      } else {
        // Now DFC requests have finished, do save the item
        resolve(this.saveFormItem({
          goBackToList,
          executeEventName,
          event,
          callbackBeforeShowViewOpen,
        }))
      }
    })
  },

  saveFormItem ({
    goBackToList = false,
    executeEventName = '',
    event,
    callbackBeforeShowViewOpen = (input) => { return new Promise<void>(resolve => resolve(input)) },
  }: SaveFormItemProps) {
    if (this.itemHasUnfinishedDfcRequests() || this.dfcRequestsComingFromTimer > 0) {
      return this.saveFormItemWhenDFCFinishes({
        goBackToList,
        executeEventName,
        event,
        callbackBeforeShowViewOpen,
      })
    }
    this.showLoaderComputed = true
    const item = this.getItemSavePayload(this.item, this.fieldsByName)
    return this.$store.dispatch('saveItem', {
      resource: this.resource,
      item,
      queries: [],
      filters: [],
      executeEventName,
      event,
    }).then((response: AxiosResponse) => {
      this.getNewTokenWhenEventActionSaveGotError(executeEventName, response)

      // Redirect when event action response requested so
      // Example Katera #/project_modules/120/edit
      // Click 'via suunnitteluun' (There's a validation that the planner must be set)
      if (this.redirectAfterSaveWithEventAction(response)) { return }

      // ID missing, release toolbar
      if (!response.data?.item?.id) {
        // Likely some error happened because of missing mandatory fields
        // release toolbar buttons (errors are displayed in global error handling)
        this.$nextTick(() => { this.showLoaderComputed = false })
        return
      }

      this.updateListAfterItemSave(executeEventName)
      // Reset item copy so route changes are allowed after save
      this.itemCopy = null

      // Handle modal item save response
      if (this.handleItemSaveResponseForModal(response)) { return }

      // Go back to list if requested
      if (this.goBackToListAfterItemSave(goBackToList)) { return }

      // Item view remains open
      // Check if item should be opened in split mode
      let alternateSplitItemViewWasOpened = false
      if (this.openAlternateSplitItemView('show')) {
        this.$nextTick(() => {
          this.showLoaderComputed = false
        })
        // Apr 2023 - still need to proceed, as item show view does remain open and must be updated
        // Otherwise initializeFormItem is not called
        // May 2023 - Set alternateSplitItemViewWasOpened = true to not use listItemOpenById
        // which would cancel the routing that was just done
        // /beta/#/project_modules/split/project_modules/170/show/project_materials/814/show
        alternateSplitItemViewWasOpened = true
        // return
      }

      // Simple item edit > save > show
      // Change route to show view
      const id = !this.item.id ? response.data.item.id : this.item.id
      callbackBeforeShowViewOpen({ event, id }).then(() => {
        // Hack check - my_profile. Do not go to show view if on my_profile
        if (!alternateSplitItemViewWasOpened && this.$route.params.specialAction !== 'myProfile') {
          this.listItemOpenById(id, { className: this.resource })
        }
        this.clearItemCurrentData(true)

        // As initializeTrigger does not change (because component remains open and no loader is shown),
        // trigger item update manually
        this.$nextTick(() => { this.initializeFormItem() })
      })
    })
  },

  itemHasUnfinishedDfcRequests () {
    return this.$store.state.queueJobKeys > 0 || !!this.$store.state.currentJobKey
  },

  itemTotalUnfinishedDfcRequestsCount () {
    return this.$store.state.queueJobKeys.length + (this.$store.state.currentJobKey ? 1 : 0)
  },

  // Return container field sets or virtual container+field-set when item layout editor not used
  fieldSetsComputed (container: LP.Container): LP.FieldSet[] {
    const fieldSets = container.id && !container.virtual ? this.containerFieldSets(container) : this.getFieldSetDefaultDataForContainer(container)
    // Set fieldSets for container object as well, to easily access later when calculating available space for container field-set
    container.fieldSets = fieldSets
    return fieldSets
  },

  // Filter container field-sets from all field-sets
  containerFieldSets (container: LP.Container) {
    return this.fieldSets.filter((fieldSet: LP.FieldSet) => {
      return fieldSet.layout_container?.id === container.id
    }).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
  },

  // Disabled for now. Until finding a case again
  // Then also must make an exception for new item
  getNewTokenWhenEventActionSaveGotError (executeEventName: string, response: AxiosResponse) {
    // Refresh item to get new token in case of event action is pressed
    // and server returns an error
    // For some reason when error is returned when saving with event btn, token is no longer valid
    // though token is ok when using regular save btn and getting error.
    // Also it is ok to reload item data because even though server returns an error item data was actually updated

    const itemWasSavedWithEventActionBtnAndGotError = executeEventName && response?.data?.status === 'error'
    if (itemWasSavedWithEventActionBtnAndGotError) {
      // UPDATE 1 - 17-SEP-22
      // This is causing issue for new item (no id yet), loses form data
      // AND now testing with Oma it does not cause token no longer valid error
      // this.initializeFormItem()
    }
  },

  handleItemSaveResponseForModal (response: AxiosResponse) {
    // Callback for modal
    if (!this.modal) { return false }
    // Item open in modal view, call callback function
    this.modalProps.saveCallback(response.data.item)
    this.$nextTick(() => {
      this.showLoaderComputed = false
    })
    return true
  },

  goBackToListAfterItemSave (goBackToList: boolean) {
    if (!goBackToList) { return false }
    // Go back to the list and close item form
    this.$router.push({ path: '/' + this.resource })
    this.$nextTick(() => {
      this.showLoaderComputed = false
    })
    return true
  },

  redirectAfterSaveWithEventAction (response: AxiosResponse) {
    // Event action redirection
    if (response.data?.redirect_to) {
      this.showLoaderComputed = false // Otherwise item form does not trigger load
      this.$nextTick(() => {
        this.redirectToAfterProcessEventAction(response)
      })
      return true
    }
    return false
  },

  updateListAfterItemSave (executeEventName) {
    // Update only saved item
    if (this.updateOnlySavedItemInTheList(executeEventName)) {
      this.$store.dispatch('updateItemInTheListById', this.item.id)
    } else {
      // Reload full list
      this.reloadListItems()
    }
  },

  // After item data save check if full list should be reloaded
  // if sorting or filtering is not affected
  // we want to update only one (saved) item
  updateOnlySavedItemInTheList (executeEventName) {
    // State filter
    const stateFilterAltered = !!(executeEventName && this.listFilters.main_object_state)

    // Check main sorting
    const sortingAltered = this.sortField && this.wasFieldChangedByTheUser(this.sortField)
    const secondarySortingAltered = Object.keys(this.secondarySortings).filter(sortField => {
      return this.wasFieldChangedByTheUser(sortField)
    }).length > 0

    // For each applied list filter
    const filteringAltered = Object.keys(this.listFilters).filter(filterFieldName => {
      return this.wasFieldChangedByTheUser(filterFieldName)
    }).length > 0

    return !stateFilterAltered && !sortingAltered && !secondarySortingAltered && !filteringAltered
  },

  wasFieldChangedByTheUser (fieldName) {
    const field = this.fieldsByNameNotLocalized[fieldName] || {}
    const key = fieldName + (field.multi_language ? '_' + this.locale : '')
    const oldValue = JSON.stringify(this.itemCopy[key])
    const newValue = JSON.stringify(this.item[key])
    return oldValue !== newValue
  },

  getHasManyItemSaveFields (items, referenceClass) {
    const amc = this.amByModel[util.objectClassUnderscoredName(referenceClass)]
    return items.map(item => {
      const newItem = {}
      Object.keys(item)
        .filter(fieldName => {
          const am = amc[fieldName]
          // return !am || am.widget !== 'simple_multiselect'
          return true
        })
        .forEach(fieldName => {
          const field = amc[fieldName]
          // console.log('.', fieldName, field.attribute_type)
          // TODO refactor
          if (fieldName.includes('@') && fieldName !== '@class') { return }
          if ((!field || field.dynamic) &&
          !['id', 'token', '@class'].includes(fieldName)) { return }
          if (field && (!field.writable || // TODO  confirm this
          !('writable' in field))) { return }
          if (amc[fieldName]?.attribute_type === 'has_many_reference') {
            // For third level (has-many child array for has-many) only send id and class (as does old portal)
            // Update 2024 - do not send has-many>has-many except for some widgets
            // Causes stale object now when "if changed?- guard" was removed from the back-end
            if (amc[fieldName].widget && ['simple_multiselect', 'file', 'image'].includes(amc[fieldName].widget)) {
              newItem[fieldName] = (item[fieldName] || []).map(arrayItem => {
                return {
                  '@class': arrayItem['@class'],
                  id: arrayItem.id,
                  token: arrayItem.token,
                }
              })
            }
          } else if (item[fieldName] && typeof item[fieldName] === 'object' && item[fieldName].id) {
            newItem[fieldName] = {
              '@class': item[fieldName]['@class'],
              id: item[fieldName].id,
            }
          // When id missing, add token
          // if (!newItem[fieldName].id) {
          //   newItem[fieldName].token = item[fieldName].token
          // }
          } else if (['file', 'image', 'pdf'].includes(amc[fieldName]?.attribute_type)) {
          // For files and images array keep only id and token, otherwise save fails
          // TODO - is there type = 'file' and only one (not array)
            newItem[fieldName] = item[fieldName].map(file => {
              Object.keys(file).forEach(fileAttribute => {
                if (!['id', 'token', '@class'].includes(fileAttribute)) {
                  delete file[fileAttribute]
                }
              })
              return file
            })
          } else {
            newItem[fieldName] = item[fieldName]
          }
        })
      return newItem
    })
  },

  isFirstFormInputField (field: LPI) {
    // With ! show to TypeScript that we are sure that the element exists
    const isScrolledFromTheTop = document.getElementById('item-form-container')!.scrollTop > 100
    return !isScrolledFromTheTop && field.id === this.firstFreeTextInputField?.id
  },

  clearItemCurrentData (forced = false) {
    if (!this.item) { return }
    // If new item with different id, clear item,
    // otherwise keep as don't want content visual flash
    if (forced || this.item.id !== parseInt(this.id)) {
      this.item = {}
    }
  },

  closeLayoutEditMode () {
    this.itemLayoutEditMode = false
    this.suppressToolbar = false
    this.highlightedFieldName = ''
  },

  confirmWindowCloseForItemPendingSave (e) {
    if (this.itemIsPendingSave) {
      e.preventDefault()
      e.returnValue = ''
    } else {
      delete e.returnValue
    }
  },

  // TODO? check @observed_members before updateItemOnDefaultsForChange?
  updateItemOnDefaultsForChange (changedField: LPI, forceObserved = false) {
    return new Promise<void>(resolve => {
      if (!changedField?.name) { return resolve() }
      console.log('-.:--==', changedField.name, this.item[changedField.name])
      const itemBeforeDfcStart = JSON.parse(JSON.stringify(this.item))
      if (!this.item?.['@observed_members']) {
        console.error('Item lost before dfc was started')
        console.log(this.item)
      }
      this.startJob().then(key => {
        this.getFormItemOnDefaultsForChange(this.resource, this.id, this.edit, {
          targetResource: this.targetResource,
          targetId: this.targetId,
          field: changedField.name,
          item: this.getItemSavePayload(this.item, this.fieldsByName),
        }).then((response: AxiosResponse | false) => {
          const itemAfterDfcEnd = JSON.parse(JSON.stringify(this.item))
          // console.log(randomIdentifierTemporary, atStart, 222, 'SET itemAfterDfcEnd', this.item.quantity, this.item.quantity_unit_price, this.item.quantity_price)
          if (response && response.data.item) {
            // Add changed field to the response as this is not returned by the API
            response.data.item[changedField.name] = this.item[changedField.name]
            const itemCopy = JSON.parse(JSON.stringify(this.itemCopy))
            this.itemCopy = JSON.parse(JSON.stringify(response.data.item))
            this.restoreItemChangesWhileDfcWasInProgress(response.data.item, itemCopy, itemBeforeDfcStart, itemAfterDfcEnd, changedField)
            // console.log(randomIdentifierTemporary, atStart, '------SET-NEW-TOTAL', response.item.quantity, response.item.quantity_unit_price, response.item.quantity_price)
            this.item = response.data.item
            this.whenQueueIsEmpty().then(() => {
              this.reloadItemHasManyLists(response.data.item, changedField.name)
            })
          }
          this.endJob(key)
          resolve()
        })
      })
    })
  },

  // Use case 1 - Reload has-many lists on dfc
  // Use case 2 - On has-many event action when reload_parent_values exists
  reloadItemHasManyLists (item: BaseItem, changedFieldName: string) {
    // 1. When specifically asked from back-end to reload has-many lists
    // Test case - /smoke_has_manies/707/edit change start_date, returns 'smoke_has_many_items' in @reload_on_defaults_for_change
    const fieldsToReloadOnDefaultsForChange = item?.['@reload_on_defaults_for_change'] || []
    // 2. When amount of items in has-many list has changed
    // this could be also included in @reload_on_defaults_for_change, but it's not
    // Test case - #/smoke_has_manies/1/edit. Change Kuljetus adds/removes a row in 'Smokella on monta yksilöt'
    this.fields.filter((field: LPI) => field.type === 'has_many_reference' && !['readers', 'writers'].includes(field.name))
      .forEach((field: LPI) => {
        const currentCount = this.$refs?.[field.name]?.[0]?.$refs?.hasManyItems?.items.length
        const newCount = item[field.name]?.length
        if (currentCount !== newCount && !fieldsToReloadOnDefaultsForChange.includes(field.name)) {
          if (field.name === changedFieldName) {
            console.warn('Skipping has-many block reload on count difference, as DFC was triggered by the same field:', changedFieldName)
          } else {
            fieldsToReloadOnDefaultsForChange.push(field.name)
          }
        }
      })
    // Ask has-many lists to reload the items
    fieldsToReloadOnDefaultsForChange.forEach((hasManyFieldName: string) => {
      const ref = this.$refs?.[hasManyFieldName]?.[0]?.$refs?.hasManyItems
      if (!ref) { return }
      ref.requestReloadWhenItemSaveQueueIsEmpty()
    })
  },

  restoreItemChangesWhileDfcWasInProgress (
    apiItem: BaseItem,
    itemCopy: BaseItem,
    itemBeforeDfcStart: BaseItem,
    itemAfterDfcEnd: BaseItem,
    changedField: LPI
  ) {
    if (!itemCopy?.['@class']) {
      console.warn('Item Copy lost. Save happened before was able to process')
      return
    }
    // debugger
    const am = this.$store.state.amByModel?.[util.objectClassUnderscoredName(apiItem?.['@class'])]
    // console.log('changedField', item['@class'], '++++++++++++++++++++++++++++', changedField.name, changedField.type, am)
    Object.keys(apiItem).forEach(fieldName => {
      const itemField = am?.[fieldName]
      // Check if user has changed local value
      // IMPORTANT - possible future issue here, local value may have been changed by the api response from some other DFC response
      // Currently we don't track if this local change was done by the user or by DFC. Issue is if this change was done during another
      // DFC-in-progress, so we don't know if value form the API should be used or not.
      // For not to fix some obvious cases let's use "dynamic" prop
      // Found problematic test case - smoke_has_many_items/2449/edit - change qty changes date +1 and does not always update
      // Possible solution: keep track of which fields have locally been changed?
      const localValueChangedDuringDFC = !itemField?.dynamic && // Ignore local changes if type is dynamic
        util.isItemFieldValueChanged(itemField, itemBeforeDfcStart[fieldName], itemAfterDfcEnd[fieldName])
      const valueChangedServerSide = util.isItemFieldValueChanged(itemField, apiItem[fieldName], itemCopy[fieldName])
      const restoreValue = localValueChangedDuringDFC || // User changed value while DFC was in progress
        (!localValueChangedDuringDFC && valueChangedServerSide) // Overwrite server value only if local value not changed

      if (changedField.name === fieldName) { // Trigger field
        // console.log('******************************************************', fieldName)
        // console.log('SERVER:', itemCopy[fieldName], '>', apiItem[fieldName])
        // console.log('LOCAL:', itemBeforeDfcStart[fieldName], '>', itemAfterDfcEnd[fieldName])
        // console.log('******************************************************')
        if (!valueChangedServerSide) {
          apiItem[fieldName] = itemAfterDfcEnd[fieldName]
        }
      } if (restoreValue) {
        const newValue = localValueChangedDuringDFC ? itemAfterDfcEnd[fieldName] : apiItem[fieldName]
        // console.log(changedField, ':::::changed ' + (localValueChangedDuringDFC ? 'locally' : 'API') + '  .....', fieldName, itemBeforeDfcStart[fieldName], ' > ', newValue)
        apiItem[fieldName] = newValue
      }
    })
  },

  // Update item in server side so has-many children can be retrieved with all necessary data
  // Called after adding a new has-many item from select
  updateItemAfterSelectingNewHasManyChild (changedFieldName: string) {
    return new Promise(resolve => {
      this.getFormItemOnDefaultsForChange(this.resource, this.id, this.edit, {
        // targetResource: this.targetResource,
        // targetId: this.targetId,
        targetResource: this.parentItem?.['@class'],
        targetId: (this.parentItem?.token || this.parentItem?.id),
        targetField: this.parentField?.name,
        field: changedFieldName,
        item: this.getItemSavePayload(this.item, this.fieldsByName),
      }).then(() => {
        // No need to use response, just need to update object in server so can call for children
        resolve(true)
      })
    })
  },

  // On has-many item event action, update main item event buttons
  updateMainItemOnHasManyChildItemEventAction (payload) {
    // Check that was triggered from the same form, in case split mode has two forms open, or modal view
    if (payload?.parent?.id !== parseInt(this.id) || util.objectClassUnderscoredName(payload?.parent?.['@class']) !== this.resource) {
      return
    }
    this.getFormItem(this.resource, this.id, this.edit, {
      targetResource: this.targetResource,
      targetId: this.targetId,
      targetField: this.targetField,
    }).then(({ item }) => {
      if (item) {
        [
          'displayable_process_events',
          'displayable_process_actions',
          'main_object_state',
          'main_object_process',
        ].forEach(fieldName => {
          this.$set(this.item, fieldName, item[fieldName])
        })
        Object.keys(this.amByModel[this.resource]).forEach(fieldName => {
          const field = this.amByModel[this.resource][fieldName]
          if (field.attribute_type === 'has_many_reference') {
            this.$set(this.item, fieldName, item[fieldName])
          }
        })
        this.reloadItemHasManyLists(item, payload?.parentField?.name)
      }
      // TODO - should also update reference type fields? Find a use case
      // Old portal seems to do it, but it also has an issue overwriting user changes
    })
  },

  initializeFormItem (props = {
    reRenderAfterItemIsLoaded: false,
  }) {
    this.$emit('setItemMeta', {})
    this.collapsedVirtualContainers = {}
    this.getItemMeta(this.id).then()
    this.getFormItem(this.resource, this.id, this.edit, {
      targetResource: this.targetResource,
      targetId: this.targetId,
      targetField: this.targetField,
    }).then(({ item, response }) => {
      this.goBackToListWhenItemNotFound(response)
      this.$store.dispatch('globalErrorDisplay', { response, context: 'Get item ' + this.resource + '/' + this.id })
      // TODO what happens if no item or error
      // Hack - my_profile. Item from api comes as not editable, why?
      if (this.$route.params.specialAction === 'myProfile' && this.resource === 'people' && item) {
        item['@editable'] = true
      }

      this.item = item
      this.itemCopy = JSON.parse(JSON.stringify(item || {}))
      // Note - don't call re-render here as some fields behave badly if creating multiple times
      // Call it in FormField for certain type
      this.$nextTick(() => {
        this.showLoaderComputed = false
        if (props.reRenderAfterItemIsLoaded) {
          this.reRender()
        }
      })
      this.createSortables()
      this.redirectToShowViewIfNotEditable(item)
      this.autofillItemTargetField()
      this.updatePageTitle()
      this.getModelDocumentation()
    })
  },

  // Autofill target reference field when new item form is opened in /for/ route
  // When field is observed, also send DFC.
  // Did explore option to use parent context in <Item> component,
  // which sends API request with /for/ as well and does auto-populate reference fields,
  // but there are issues with has-many lists. Plus old portal did not do that as well,
  // likely because of this same reason.
  autofillItemTargetField () {
    this.$nextTick(() => {
      // Check conditions when to auto-populate
      const isForView = this.$route.name === 'List_forTargetWithForm'
      const isSupportedFormPosition = this.itemFormPosition === 'first'
      const isNew = this.$route.params.id === 'new'
      if (!isForView || !isSupportedFormPosition || !isNew) { return }
      // Get target context and filed name from the item to autofill
      const targetResource = this.$route.params?.targetResource
      const targetField = this.fields?.find(field => field.type === 'reference' &&
        util.objectClassUnderscoredName(field.reference_class) === targetResource)
      if (!targetField?.name) { return }
      // Set the value
      this.$set(this.item, targetField.name, {
        id: this.$route.params.targetId,
        summary: this.listForItemName,
      })
      // Call for DFC, if observed
      if (this.item['@observed_members'].includes(targetField.name)) {
        this.updateItemOnDefaultsForChange(targetField)
      }
    })
  },

  goBackToListWhenItemNotFound (response: AxiosResponse) {
    const responseCode = response.status
    if (responseCode === 404 && response.data?.messages?.[0]?.text === 'Aava::Model::ObjectNotFound') {
      this.$router.push({ path: '/' + this.resource })
    }
  },

  initializeSameItemAfterLayoutProfileChange () {
    // When Layout Profile changes in edit view, do not load item data again
    // User can switch between LP tabs, edit item info and save once
    this.showLoaderComputed = false
    this.createSortables()
  },

  // Get item meta to show action buttons and get permissions
  getItemMeta (id: number | string): Promise<AxiosResponse<ItemMeta> | null> {
    return new Promise(resolve => {
      if (!id) { return resolve(null) }
      api.fetchItemMeta(this.resource, id).then((response: AxiosResponse<ItemMeta>) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Item meta' })
        this.$emit('setItemMeta', response.data || {})
        resolve(response)
      })
    })
  },

  getModelDocumentation () {
    if (this.documentationByModel[this.resource]) { return }
    return api.fetchModelDocumentation(util.objectClassSingularName(this.resource), this.locale)
      .then((response: AxiosResponse | null) => {
        if (response?.data?.content) {
          this.$set(this.documentationByModel, this.resource, response.data.content)
        }
      })
  },

  updatePageTitle () {
    if (this.modal) { return }
    this.$nextTick(() => {
      window.document.title = this.$i18n.t(this.resource + '.show.title') + ' > ' + this.item.summary
    })
  },

  reRender () {
    this.render = false
    this.$nextTick(() => {
      this.render = true
    })
  },

  redirectToShowViewIfNotEditable (item) {
    if (!item || !this.edit || item['@editable'] === true) {
      return
    }
    const messageKey = item['@final_state'] ? 'aava.edit.messages.object_in_final_state' : 'aava.edit.messages.object_not_editable'
    this.$store.dispatch('showMessage', {
      message: this.$i18n.t(messageKey),
      type: 'error'
    })
    if (!this.openAlternateSplitItemView('show')) {
      this.listItemOpenById(item.id, { className: this.resource })
    }
  },

  handleItemScroll (e) {
    if (e.target) {
      this.itemScrolledTop = e.target.scrollTop
    }
  },

  fieldSetClasses (container) {
    const heightHelperClass = this.itemLayoutEditMode
      ? ''
      : (container['name_' + this.locale]) ? 'fill-height-minus-title' : 'fill-height'
    return [
      'fieldset-row',

      // Disabled d-none for container.collapsed on 4-JUL-23
      // Had problem with boolean component where it tried to position visual elements by using clientWidth
      // which was not possible with display:hidden
      // Also may be better not to load Has-Many items for collapsed attribute groups,
      // which now is not done when <div> is not rendered in the DOM
      // (container.collapsed || this.collapsedVirtualContainers[container?.id] ? 'd-none' : ''),

      heightHelperClass,
    ]
  },

  // TODO - comment
  saveChangedHasManyItemTemporaryObjects () {
    if (!this.edit) { return }
    const hasManyComponents = Object.keys(this.$refs).filter(key => {
      return Array.isArray(this.$refs[key]) &&
        this.$refs?.[key]?.[0]?.field.type === 'has_many_reference' &&
        this.$refs?.[key]?.[0].$refs.hasManyItems
    }).map(key => this.$refs?.[key]?.[0].$refs.hasManyItems)
    hasManyComponents.forEach(hasMany => {
      hasMany.$children.filter(el => el.resource && !el.readOnly && el.itemDataHasChange).forEach(hasManyComponent => {
        if (!hasManyComponent.readOnly) {
          hasManyComponent.getHasManyItemDfcChanges({ name: 'updated_at' })
        }
      })
    })
  },
}
