import sharedUtilities from '@/utilities/sharedUtilities'
import { LP, LPI } from '@/types/LP.types'
import AMC = LP.AMC

const attachmentQueries = 'filename,content_type,attachable_association,order'

const stateQueries = 'initial_state,short_name,final_state,text_color,background_color,name'

const polymorphicAutocompleteQueries = 'main_object_state background_color,color,short_name,identifier,name'

const addressQueries = [
  'summary,token,street_1,street_2,street_3,street_4,postal_code_string,postal_office_string',
  'country summary'
]

export interface getPayload {
  locale?: string,
  view?: 'hasManyItemEdit' | 'list' | 'item',
  forExport?: boolean,
  edit?: boolean,
  onDefaultsForChange?: boolean,
  availableContentLocales?: string[],
  includeAllFields?: boolean,
  pivotTableFields?: string[],
}

const standardQueries = [
  '@displayable_documents',
  '@show_has_many_association_process_state', // TODO not used?
  '@observed_members',
  '@member_styles',
  '@editable',
  // '@prevent_write_members',
  '@editable_members',
]

const itemEditQueries = [
  '@observed_members',
  '@editable',
  '@displayable_documents',
  '@member_styles',
  '@hidden_members',
  '@prevent_write_members',
  '@title_postfix',
  '@reload_on_defaults_for_change',
  'displayable_process_events',
  'displayable_process_actions',
  'owner name?',
  'created_at',
  'updated_at',
  'created_by name?',
  'updated_by name?',
]

const hasManyItemEditQueries = [
  '@editable_members',
  '@observed_members',
  '@member_styles',
  '@editable',
  '@prevent_write_members',
  '@hidden_members',
]

export default {
  getQuantityUnitQueries: (fields) => {
    return (fields || [])
      .filter(field => field.visible && field.type === 'quantity')
      .map(field => field.name + '_unit name,decimals_display')
  },

  getPriceUnitQueries: (fields) => {
    return (fields || [])
      .filter(field => field.visible && field.type === 'price')
      .map(field => field.name + '_currency identifier,decimals_display')
  },

  getHasManyFieldsWithData: (selectedLayoutProfile) => selectedLayoutProfile &&
      selectedLayoutProfile.timelineConfiguration &&
      selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData
    ? selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData
    : [],

  isAddress: (field) => {
    return field.reference_class === 'Address'
  },

  getQueriesForHasManyItems: function (fields, {
    amc,
    edit,
    locale,
    view,
    parentField,
  }: {
    amc: Record<string, AMC>
    edit: boolean
    locale: string
    view: string
    // For multiselect to access widgetConfig
    parentField?: LPI | undefined
  }): Promise<string[]> {
    return new Promise(resolve => {
      const amcForQueryFields: any = []
      for (const field of fields) {
        const fieldInfo: any = amc[field.name] || {
          name: field.name,
          type: 'string',
        }
        fieldInfo.name = fieldInfo.name || fieldInfo.attribute_name
        fieldInfo.type = fieldInfo.type || fieldInfo.attribute_type
        fieldInfo.visible = true
        amcForQueryFields.push(fieldInfo)
      }
      const queries = this.get(amcForQueryFields, null, { locale, view })
      if (edit) {
        queries.push('token')
      }
      // Add MULTISELECT has-many component additional queries
      // Selected and shown by parent attribute
      if (parentField?.widgetConfig?.multiselect_parent_attribute) {
        const summaryField = parentField.widgetConfig?.multiselect_input_display_attribute || 'summary'
        queries.push(parentField.widgetConfig?.multiselect_parent_attribute + ' ' + summaryField)
      } else if (parentField?.widgetConfig?.multiselect_input_display_attribute) {
        // "Regular" multiselect. Set display attribute instead of default 'summary'
        queries.push(parentField.widgetConfig?.multiselect_input_display_attribute)
      }
      resolve(queries)
    })
  },

  get: function (layoutProfileItems: LP.Item[], selectedLayoutProfile: LP.LayoutProfile | null, {
    forExport = false,
    view = 'list',
    edit = false,
    locale,
    onDefaultsForChange = false,
    availableContentLocales = [], // For item edit get values for all locales
    includeAllFields = false,
    // Pivot table view can have additional fields, compared to what are visible in the list
    pivotTableFields = [],
  }: getPayload) {
    const additionalQueries: string[] = []
    const queries = (layoutProfileItems || [])
      .filter(field => field.visible ||
        field.name === 'main_object_state' ||
        includeAllFields ||
        pivotTableFields.includes(field.name)
      ).map(field => {
        if (forExport) {
          return field.name
        }
        if (sharedUtilities.isAttachment(field) || sharedUtilities.isImageType(field)) {
          return field.name + ' ' + attachmentQueries
        }
        const localePrefixDisabled = view !== 'item'
        switch (field.type) {
          case 'polymorphic_autocomplete':
            additionalQueries.push(field.name + ' ' + polymorphicAutocompleteQueries)
            additionalQueries.push(field.name + ' @association_style')
            return field.name + ' ' + (field.reference_attribute || 'summary')
          case 'reference':
            if (!onDefaultsForChange) {
              additionalQueries.push(field.name + ' main_object_state short_name,identifier,name,background_color,initial_state,final_state')
              additionalQueries.push(field.name + ' @association_style')
            }
            if (field.widget === 'address' && view === 'item') {
              additionalQueries.push(field.name + ' ' + addressQueries[0])
              return field.name + ' ' + addressQueries[1]
            }
            return field.name + ' ' + (field.reference_attribute || 'summary')
          case 'state':
            if (field.name === 'main_object_state') {
              return field.name + ' ' + field.reference_attribute + ',' + stateQueries
            }
            return field.name + ' ' + (field.reference_attribute || 'summary')
          case 'process':
            // Always get main_object_state when process is requested
            // TODO - don't send double
            additionalQueries.push('main_object_state ' + stateQueries)
            return field.name + ' summary'
          case 'process_events':
            additionalQueries.push('displayable_process_actions')
            return 'displayable_process_events'
          case 'event':
          case 'action':
            additionalQueries.push('displayable_process_events')
            additionalQueries.push('displayable_process_actions')
            return field.name
          case 'has_many_reference':
            // Add MULTISELECT has-many queries
            if (field.widget === 'simple_multiselect') {
              // A) In case of parent reference attribute
              if (field.widgetConfig?.multiselect_parent_attribute) {
                additionalQueries.push(field.name + ' ' + field.widgetConfig?.multiselect_parent_attribute)
                additionalQueries.push(field.name + ' ' + field.widgetConfig?.multiselect_parent_attribute + ' summary' +
                    (field.widgetConfig.multiselect_input_display_attribute ? ',' + field.widgetConfig.multiselect_input_display_attribute : ''))
              } else if (field.widgetConfig?.multiselect_input_display_attribute) {
                // Without parent, normal usage
                additionalQueries.push(field.name + ' ' + field.widgetConfig?.multiselect_input_display_attribute)
              }
            }
            if (view !== 'item') {
              additionalQueries.push(field.name + ' @association_style')
            }
            if (view === 'item') {
              // Get has-many items with token for the edit form,
              // so it's ok to save the main form before all has-many lists are loaded
              // But for show view still need @count
              return edit ? field.name + ' ' + 'token' : field.name + ' @count'
            }
            return field.name + ' ' +
              (this.getHasManyFieldsWithData(selectedLayoutProfile).includes(field.name) ||
                ['item', 'hasManyItemEdit'].includes(view!)
                ? field.reference_attribute
                : '@count')
          default:
            if (view === 'item' && field.multi_language) {
              availableContentLocales.filter(availableLocale => availableLocale !== locale)
                .forEach(availableLocale => {
                  additionalQueries.push(field.name + '_' + availableLocale)
                })
            }
            return field.name + (field.multi_language && !localePrefixDisabled && !['hasManyItemEdit'].includes(view!)
              ? '_' + locale
              : '')
        }
      })
    queries.push('summary') // For page title
    const combined = [
      ...(view === 'item' ? itemEditQueries : []),
      ...(view === 'hasManyItemEdit' ? hasManyItemEditQueries : []),
      ...queries,
      ...(!forExport && !['item', 'hasManyItemEdit'].includes(view)
        ? standardQueries
        : []),
      ...this.getQuantityUnitQueries(layoutProfileItems),
      ...this.getPriceUnitQueries(layoutProfileItems)
    ]
    additionalQueries.forEach(query => {
      if (!combined.includes(query)) {
        combined.push(query)
      }
    })
    return combined
  }
}
